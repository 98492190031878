<template>
  <div class="main-div">
    <div class="inner-div">
      <div class="vasion-run-report-prompt">
        <VasionEmptyReportsIcon />
        <div class="vasion-large-message">
          Please select a Report
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyReport',
}
</script>

<style lang="scss" scoped>
.inner-div {
  margin: 0px 16px 10px 15px;
}
</style>
